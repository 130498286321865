@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-num-arrows-chrome::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hide-num-arrows-moz[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

.hidden {
    display: none !important;
}

.hover-parent:hover .hidden {
    display: flex !important;
}

#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #ffffff00; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
