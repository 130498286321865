@import './base.css';
@import './components.css';
@import './utilities.css';

@font-face {
    font-family: 'Mona Sans';
    font-weight: 200 900;
    font-display: block;
    font-style: normal;
    font-stretch: 75% 125%;
    src: url('../../fonts/Mona-Sans.var.woff2') format('woff2');
}

.typography {
    color: theme(colors.neutral.900);
    font-size: theme(fontSize.xl);
    line-height: theme(fontSize.xl[1].lineHeight);

    --shiki-color-text: theme(colors.white);
    --shiki-color-background: theme(colors.neutral.900);
    --shiki-token-constant: theme(colors.neutral.300);
    --shiki-token-string: theme(colors.neutral.400);
    --shiki-token-comment: theme(colors.neutral.500);
    --shiki-token-keyword: theme(colors.neutral.400);
    --shiki-token-parameter: theme(colors.neutral.400);
    --shiki-token-function: theme(colors.neutral.300);
    --shiki-token-string-expression: theme(colors.neutral.300);
    --shiki-token-punctuation: theme(colors.neutral.400);

    :where(.typography > *) {
        margin-top: theme(spacing.6);
        margin-bottom: theme(spacing.6);
    }

    /* Headings */
    :where(h2) {
        font-weight: theme(fontWeight.semibold);
        font-family: theme(fontFamily.display);
        font-variation-settings: theme(
            fontFamily.display[1].fontVariationSettings
        );
        font-size: theme(fontSize.2xl);
        line-height: theme(fontSize.2xl[1].lineHeight);
        margin-top: theme(spacing.16);
    }

    :where(h3) {
        font-weight: theme(fontWeight.semibold);
        font-family: theme(fontFamily.display);
        font-variation-settings: theme(
            fontFamily.display[1].fontVariationSettings
        );
        font-size: theme(fontSize.xl);
        line-height: theme(fontSize.xl[1].lineHeight);
        margin-top: theme(spacing.10);
    }

    :where(h2 + h3) {
        margin-top: 0;
    }

    /* Lists */
    :where(ul, ol) {
        padding-left: 1.5rem;
    }

    :where(ul) {
        list-style-type: disc;
    }

    :where(ol) {
        list-style-type: decimal;
    }

    :where(li) {
        padding-left: theme(spacing.3);
        margin-top: theme(spacing.6);
    }

    :where(li)::marker {
        color: theme(colors.neutral.500);
    }

    :where(li > *),
    :where(li li) {
        margin-top: theme(spacing.4);
    }

    :where(ol > li)::marker {
        font-size: theme(fontSize.base);
        font-weight: theme(fontWeight.semibold);
    }

    /* Tables */
    :where(table) {
        width: 100%;
        text-align: left;
        font-size: theme(fontSize.base);
        line-height: theme(fontSize.base[1].lineHeight);
    }

    :where(th) {
        font-weight: theme(fontWeight.semibold);
    }

    :where(thead th) {
        padding-bottom: theme(spacing.6);
        border-bottom: 1px solid theme(colors.neutral.900);
    }

    :where(td) {
        vertical-align: top;
        padding-top: theme(spacing.6);
        padding-bottom: theme(spacing.6);
        border-bottom: 1px solid theme(colors.neutral.900 / 0.1);
    }

    :where(:is(th, td):not(:last-child)) {
        padding-right: theme(spacing.6);
    }

    /* Code blocks */
    :where(pre) {
        display: flex;
        background-color: theme(colors.neutral.900);
        border-radius: theme(borderRadius.4xl);
        overflow-x: auto;
        margin-top: theme(spacing.10);
        margin-bottom: theme(spacing.10);
        margin-left: calc(-1 * theme(spacing.6));
        margin-right: calc(-1 * theme(spacing.6));

        @screen sm {
            margin-left: auto;
            margin-right: auto;
        }
    }

    :where(pre code) {
        flex: none;
        padding: theme(padding.8) theme(padding.6);
        font-size: theme(fontSize.base);
        line-height: theme(lineHeight.8);
        color: theme(colors.white);

        @screen sm {
            padding: theme(spacing.10);
        }
    }

    /* <hr> */
    :where(hr) {
        border-color: theme(colors.neutral.900 / 0.1);
        margin-top: theme(spacing.24);
        margin-bottom: theme(spacing.24);
    }

    /* Inline text */
    :where(a) {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 0.15em;
        text-decoration-skip-ink: none;
        font-weight: theme(fontWeight.semibold);
    }

    :where(strong) {
        font-weight: theme(fontWeight.semibold);
    }

    :where(code:not(pre code)) {
        font-size: calc(18 / 20 * 1em);
        font-weight: theme(fontWeight.semibold);

        &::before,
        &::after {
            content: '`';
        }
    }

    :where(h2 code, h3 code) {
        font-weight: theme(fontWeight.bold);
    }

    /* Figures */
    :where(figure) {
        margin-top: theme(spacing.32);
        margin-bottom: theme(spacing.32);
    }

    /* Spacing overrides */
    :where(.typography:first-child > :first-child),
    :where(li > :first-child) {
        margin-top: 0 !important;
    }

    :where(.typography:last-child > :last-child),
    :where(li > :last-child) {
        margin-bottom: 0 !important;
    }
}
