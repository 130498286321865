html {
    background-color: #f3f4f6;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.amplify-button--primary {
    background-color: rgb(25 101 219)
}

.amplify-button--primary:hover {
    background-color: rgb(22 86 185)
}

.amplify-tabs-item[data-state=active] {
    color: rgb(25 101 219);
    border-color: rgb(25 101 219)
}

.amplify-tabs-item:hover {
    color: rgb(22 86 185)
}

.amplify-button--link {
    color: rgb(22 86 185)
}

.amplify-button--link:hover {
    color: rgb(22 86 185);
    background-color:rgb(209, 225, 250)
}

.amplify-button:hover {
    border-color: rgb(140, 179, 242);
}

.amplify-button:focus {
    border-color: rgb(140, 179, 242);
    background-color: rgb(209, 225, 250)
}

[data-amplify-authenticator] {
    height: 100vh
}